<template>
  <div class="Portfolio">
    <Hero title="Portfolio" :pictures="[ 'beach.jpg', 'garden.jpg', 'chongqing.jpg' ]" :height="75"/>
    <div class="Portfolio__intro">
      <p>Description of my work will be gathered here <br>
        From small experiments to more remarkable professionnal projects, I won't promise it's up to date</p>
    </div>
    <transition name="fade">
      <div class="Portfolio__loaderContainer" v-if="loadingProjects">
        <div class="Portfolio__loader">
          <img src="../assets/triangles-loader.svg" alt="">
        </div> 
      </div>
    </transition>

    <CardPresenter v-if="!loadingProjects && projects" 
                  :items="projects" 
                  @item-selected="openModal"/>
    <router-view v-slot="{ Component }">
      <Modal transitionMode="">
        <ProjectCarousel class="Portfolio__projectNavigation" v-if="$route.meta.modal" 
                         @click-close="closeModal" 
                         :style="{ transformOrigin: `${x}px ${y}px` }" 
                         @click-previous="seePreviousProject" 
                         @click-next="seeNextProject">
          <transition :name="transitionName">
            <keep-alive>
            <component :is="Component" 
                        :key="$route.params.id" 
                        :class="{ 'revert': revertTransition }" 
                        class="Portfolio__project" />
            </keep-alive>
          </transition>
        </ProjectCarousel>
      </Modal>
    </router-view>
  </div>
</template>

<script>
import Hero from '@/components/Hero'
import CardPresenter from '@/components/CardPresenter'
import Modal from '@/components/Modal'
import ProjectCarousel from '@/components/ProjectCarousel'
import useProjects from '@/composables/useProjects'
import useSwipe from '@/composables/useSwipe'
import { useRouter, onBeforeRouteUpdate, useRoute } from 'vue-router'
import { reactive, ref, toRefs } from 'vue'

export default {
    name: 'Portfolio',
    components: {
        Hero,
        CardPresenter,
        Modal,
        ProjectCarousel
    },
    setup () {
      const { getProjects } = useProjects()
      const { projects, loading: loadingProjects } = getProjects()

      const router = useRouter()
      const route = useRoute()

      const mousePos = reactive({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
      })

      function openModal ({ item, e }) {
        document.body.style.overflow = 'hidden'
        mousePos.x = e.clientX
        mousePos.y = e.clientY
        router.push({ name: 'project', params: { id: item.id }})
      }

      function closeModal () {
        document.body.style.overflow = 'auto'
        router.push({ name: 'Portfolio' })
      }

      const revertTransition = ref(true)
      const transitionName = ref('fade')
      onBeforeRouteUpdate((to, from) => {
        if (to.params.id < 1)
          return false
        
        transitionName.value = from.meta.modal && to.meta.modal ? 'slide-full' : 'fade'
      })

      window.onkeyup = function handleKeyedNavigation (e) {
        if (route.meta.modal) {
          switch (e.key) {
            case 'ArrowLeft':
              seePreviousProject()
              break
            case 'ArrowRight':
              seeNextProject()
              break
            case 'Escape':
              closeModal()
              break
            default:
              break;
          }
        }
      } 

      function slideProject (side) {
        if (route.params.id) {
          const currentProject = projects.value.find(p => p.id === parseInt(route.params.id))
          const currentIndex = projects.value.indexOf(currentProject)
          const nextId = projects.value[side === 'left' ? currentIndex - 1 : currentIndex + 1]?.id
          if (!nextId) return
          revertTransition.value = side === 'left'
          router.push({ name: 'project', params: { id: nextId } })
        }
      }

      function seeNextProject () {
        slideProject('right')
      }

      function seePreviousProject () {
        slideProject('left')
      }
      
      useSwipe({
        handlers: {
          onSwipeLeft: seePreviousProject,
          onSwipeRight: seeNextProject
        },
        element: window.document
      })
        
      return { 
        projects,
        openModal,
        closeModal,
        loadingProjects,
        ...toRefs(mousePos),
        transitionName,
        revertTransition,
        seeNextProject,
        seePreviousProject,
      }
    }
}
</script>

<style lang="scss" scoped>
  .Portfolio {

    &__intro {
      background-color: #f2f2f2;
      font-size: 2em;
      padding: 8%;
      text-align: center;
    }

    &__loader {
      position: absolute;
      left: calc(50% - 100px);
      padding: 5% 0;

      &Container {
        // Loader goes through footer otherwise
        height: 350px;
      }
    }

    &__project{
      // Necessary or the width while transitionning isn't correct
      position: absolute;
    }
  }
</style>

<template>
    <div class="ProjectCarousel">
        <CloseButton @click="$emit('click-close')" class="ProjectCarousel__CloseBtn"/>
        <a @click="$emit('click-previous')">
            <img src="../assets/left.svg" alt="left" width="40">
        </a>
        <div class="content">
            <slot></slot>
        </div>
        <a @click="$emit('click-next')">
            <img src="../assets/left.svg" alt="left" style="transform: rotateY(180deg)" width="40">
        </a>
    </div>
</template>

<script>
import CloseButton from './CloseButton'

export default {
    name: 'ProjectCarousel',
    components: {
        CloseButton
    }
}
</script>

<style lang="scss" scoped>
    .ProjectCarousel {

        // background: linear-gradient(135deg, rgba(1,0,1,0.95) 0%, rgba(13,0,34,0.95) 46%, rgba(22,0,102,0.95) 85%, rgba(0,82,135,0.95) 100%);
        background: linear-gradient(135deg, rgba(19, 0, 51, .95) 3%, rgba(2, 0, 36, .95) 50%, rgba(19, 0, 51, .95) 97%);
        // background: radial-gradient(circle, #020024 40%, #130033 80%);
        height: 90vh;
        width: 100vw;
        padding: 5vh 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 460px) {
          height: 80vh;
          padding: 10vh 0;
        }

        a {
          margin: 3%;
          cursor: pointer;

          img {
            width: 30px;

            @media (min-width: 460px) {
              width: 40px;
            }
          }
        }

        .content {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &__CloseBtn {
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }
</style>
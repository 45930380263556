<template>
  <transition :name="transitionName" :mode="transitionMode" class="Modal">
      <slot></slot>
  </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        transitionName: {
            type: String,
            default: 'fade'
        },
        transitionMode: {
            type: String,
            default: 'out-in'
        }
    }
}
</script>

<style lang="scss" scoped>
    .Modal {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1000;
    }
</style>
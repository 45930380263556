<template>
<div class="CardPresenter">
    <Card v-for="(item, index) in items" 
          :key="index" 
          class="CardPresenter__card" 
          @click="e => $emit('item-selected', { item, e })"
          :title="item.title" 
          :description="item.shortDescription" 
          :source="item.media" 
          @is-mounted.once="handleCardMounted" />
</div>
</template>

<script>
import Card from '@/components/Card'

export default {
    name: 'CardPresenter',
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    components: {
        Card
    },
    setup () {
        const observer = new IntersectionObserver(handleIntersect, {})

        function handleIntersect (entries) {
            entries.forEach(function(entry, index) {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        entry.target.classList.add('blossom')
                    }, index%3 * 250)
                }
            });
        }

        function handleCardMounted (element) {
            observer.observe(element)
        }

        return {handleCardMounted}
    },
    emits: ['item-selected']
}
</script>

<style lang="scss" scoped>
.blossom {
  animation-name: blossom;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.CardPresenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__card {
        transform: scale(0.1);
        // flex-basis: 80%;
        display: flex;
        justify-content: center;
        cursor: pointer;
        @media (max-device-width: 500px) {
            flex-basis: 80%;
            .card {
            width: 600px;
            height: 500px;
            .card-img {
                width: 600px;
                height: 500px;
            }
            }
        }
    }
}

@keyframes blossom {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}
</style>

const { ref } = require("vue");

export default function useSwipe ({element, handlers, }) {
    const touchStart = ref(null)
    const touchEnd = ref(null)
    const deltaX = ref(0)
    const deltaY = ref(0)
    const directionThreshold = 50
    element.addEventListener('touchstart', ({touches}) => touchStart.value = touches[0])
    element.addEventListener('touchmove', ({touches}) => touchEnd.value = touches[0])
    element.addEventListener('touchend', () => {
        if (touchStart.value && touchEnd.value) {
            deltaX.value = touchEnd.value.clientX - touchStart.value.clientX
            deltaY.value = touchEnd.value.clientY - touchStart.value.clientY

            if (deltaX.value > 0 && Math.abs(deltaY.value) < directionThreshold) {
                handlers.onSwipeLeft()
            } else if (deltaX.value < 0 && Math.abs(deltaY.value) < directionThreshold) {
                handlers.onSwipeRight()
            }
        }
        
    })
}